import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import AppPage from "./pages/AppPage";
import HowToUse from "./pages/HowToUse";
import Demo from "./pages/Demo";
import LegalPage from "./pages/LegalPage";
import termsData from "./data/legal/termsData";
import privacyData from "./data/legal/privacyData";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<LegalPage data={termsData} />} />
        <Route path="/privacy" element={<LegalPage data={privacyData} />} />
        <Route path="/:appId" element={<AppPage />} />
        <Route path="/:appId/how-to-use" element={<HowToUse />} />
        <Route path="/:appId/demo" element={<Demo />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
