import { useParams } from "react-router-dom";
import apps from "../data/apps/apps";

const HowToUse = () => {
  const { appId } = useParams();
  const { name, instructions, demoVideoId } = apps[appId];

  return (
    <main>
      <div className="bg-primary-dark">
        <div className="container flex flex-col items-center gap-5 py-10 text-white">
          <h3>{name}</h3>
          <h2>How To Use</h2>
        </div>
      </div>

      <div className="py-10">
        <div className="container">
          <ul>
            {instructions.map(({ text, images, vertical, noIndex, subpoints }, index) => (
              <li className="flex flex-col gap-10 py-10">
                <div className="flex gap-4 px-20">
                  {!noIndex && <p className="text-primary-dark p2-r">{index + 1}.</p>}
                  <p className="text-primary-dark p2-r">{text}</p>
                </div>
                {vertical ? (
                  <div className="flex justify-center gap-10">
                    {images.map((image) => (
                      <img
                        src={image}
                        alt="App screenshot for this step"
                        className="w-[40%] rounded-xl border-2 border-secondary-light"
                      />
                    ))}
                  </div>
                ) : (
                  <>
                    {images.map((image) => (
                      <img
                        src={image}
                        alt="App screenshot for this step"
                        className="w-[70%] mx-auto rounded-xl border-2 border-secondary-light"
                      />
                    ))}
                  </>
                )}
                {subpoints && (
                  <ul className="flex flex-col gap-4 px-20">
                    {subpoints.map((point) => (
                      <li className="p3-r text-primary-dark">{point}</li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>

          <div className="flex flex-col items-center gap-6 py-10">
            <p className="text-primary-dark p2-b">
              For video explanation, checkout the demo video below:
            </p>
            <iframe
              width="960"
              height="540"
              src={`https://www.youtube.com/embed/${demoVideoId}`}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              title={`${name} demo video`}
              className="mx-auto rounded-3xl"
            ></iframe>
          </div>
        </div>
      </div>
    </main>
  );
};

export default HowToUse;
