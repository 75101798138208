import mainImage from "../../images/apps/srt/main.png";
import step1 from "../../images/apps/srt/instructions/step-1.png";
import step2_1 from "../../images/apps/srt/instructions/step-2-1.png";
import step2_2 from "../../images/apps/srt/instructions/step-2-2.png";
import step3_1 from "../../images/apps/srt/instructions/step-3-1.png";
import step3_2 from "../../images/apps/srt/instructions/step-3-2.png";
import step4_1 from "../../images/apps/srt/instructions/step-4-1.png";
import step4_2 from "../../images/apps/srt/instructions/step-4-2.png";
import step5 from "../../images/apps/srt/instructions/step-5.png";
import step6_1 from "../../images/apps/srt/instructions/step-6-1.png";
import step6_2 from "../../images/apps/srt/instructions/step-6-2.png";
import step7_1 from "../../images/apps/srt/instructions/step-7-1.png";
import step7_2 from "../../images/apps/srt/instructions/step-7-2.png";
import feature1 from "../../images/apps/srt/features/feature-1.png";
import feature2 from "../../images/apps/srt/features/feature-2.png";
import feature3 from "../../images/apps/srt/features/feature-3.png";
import feature4 from "../../images/apps/srt/features/feature-4.png";
import feature5 from "../../images/apps/srt/features/feature-5.png";

const srtData = {
  name: "Schedule Recurring Tasks",
  introText:
    "Introducing the ultimate solution to your task scheduling challenges on monday.com! Say goodbye to suboptimal workarounds and welcome a powerful, efficient, and flexible tool that revolutionizes the way you manage recurring tasks.",
  subtext:
    "Now you can schedule your tasks to appear on specific dates and times, and manage them with ease.",
  image: mainImage,
  clientId: "813af5547017c7690503108da29dd4ba",
  promoVideoId: "vyBo8KSPad4",
  demoVideoId: "DfI1NW0rfvA",
  features: [
    {
      name: "Highly configurable scheduling settings",
      text: "You can choose any period and granularity for your schedule (daily, weekly, monthly, quarterly, bi-annually, annually, or even custom) as per your requirements",
      image: feature1,
    },
    {
      name: "Calendar widget for easy visualization",
      text: "Clear calendar widget showing the next occurence of scheduled tasks so you are double sure of when it will appear next",
      image: feature2,
    },
    {
      name: "Advanced setting options",
      text: "Allows you to set target group, status and due date for the scheduled items, so that you can manage your tasks in a more organized way",
      image: feature3,
    },
    {
      name: "Custom date based name",
      text: "Allows you to set date based names on your scheduled items, so that when you items are created you know exactly what and when of it",
      image: feature4,
    },
    {
      name: "See all your schedules in one place",
      text: "Board view showing all your schedules in one place, so you can easily manage them",
      image: feature5,
    },
  ],
  instructions: [
    {
      text: "Open the item/task for which you want to set up a recurring schedule.",
      images: [step1],
    },
    {
      text: "Press the plus button and add the 'Schedule Recurring Tasks' item view as shown",
      images: [step2_1, step2_2],
    },
    {
      text: "When you open the app first time it will ask for relevant permission. Once you review the permissions and authorize the app, you will be able to see the schedule screen.",
      images: [step3_1, step3_2],
      vertical: true,
    },
    {
      text: "Now you can configure the period, granularity and start date for the schedule as per your requirements. In this case we are scheduling a task to represent a meeting which happens on two days (Monday and Thursday) and happens every alternate week. Timezone field is required to create item on correct date and well as for due date calculations.",
      images: [step4_1, step4_2],
      vertical: true,
    },
    {
      text: "Apart from basic date settings you can also set the following settings: 1) Target Group - You can select a target group, so that when item is created it will be moved to that group; 2) Status - You can select one status type column from your board and its particular value, so that when item is created, the status will be set to that value; 3) Due Date - You can select one date type column from your board and input a number of days value, so that when item is created, the due date is calculated and filled as creation date + input days value.",
      images: [step5],
      vertical: true,
    },
    {
      text: 'Once you are done with all the settings, press "Schedule" button to schedule the task. Now whenever the date condition will be satisfied an item/task will be automatically created in your board. You can also edit any settings anytime by pressing "Edit Schedule" button, making relevant changes and then pressing "Save Changes" button.',
      images: [step6_1, step6_2],
      vertical: true,
    },

    {
      text: "If you want to stop a schedule for a while, you can simple press 'Unschedule' button and it will not create any more tasks even when the date condition is satisfied. In future, you can reshedule it back by pressing the 'Reschedule' button.",
      images: [step7_1, step7_2],
      vertical: true,
    },
  ],
  faqs: [
    {
      question: "Do I need to buy this app for each board separately ?",
      answer:
        "No, you only have to purchase it on a company level, and then you can use it on all your boards.",
    },
    {
      question:
        "Will this app create item as per schedule even if I have archived the original item ?",
      answer: "Yes, this app will create item even if the original item is archived.",
    },
    {
      question:
        "I just want status of item to be updated and no new item to be created, can I do that ?",
      answer:
        'Yes, you can disable the "Duplicate Item" option and set status setting as per your need. In such a case the status will be updated at scheduled date but no new item will be created.',
    },
  ],
};

export default srtData;
