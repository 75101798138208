const Paragraph = ({ text, linkText, link }) => {
  if (!link) {
    return <p className="p2-r text-secondary-dark">{text}</p>;
  }

  const preText = text.split(linkText)[0];
  const postText = text.split(linkText)[1];

  return (
    <p className="p2-r text-secondary-dark">
      {preText}
      <a href={link} className="text-blue-600">
        {linkText}
      </a>
      {postText}
    </p>
  );
};

export default Paragraph;
