import { useEffect } from "react";
import List from "../components/legal/List";
import Paragraph from "../components/legal/Paragraph";

const LegalPage = ({ data: { title, updated, sections } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="bg-secondary-light">
      <div className="container py-20">
        <div className="flex flex-col justify-center items-center gap-2 pb-20">
          <h2 className="text-primary-dark">{title}</h2>
          <p className="p3-b text-secondary-dark">Last Updated: {updated}</p>
        </div>

        <div className="flex flex-col gap-10">
          {sections.map(({ heading, children }) => (
            <div className="flex flex-col gap-4">
              <p className="p1-b text-primary-dark">{heading}</p>
              {children.map(({ text, linkText, link, list }) =>
                list ? (
                  <List list={list} />
                ) : (
                  <Paragraph text={text} linkText={linkText} link={link} />
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default LegalPage;
