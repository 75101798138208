import mainImage from "../../images/apps/gcs/main.png";
import step1 from "../../images/apps/gcs/instructions/step-1.png";
import step2_1 from "../../images/apps/gcs/instructions/step-2-1.png";
import step2_2 from "../../images/apps/gcs/instructions/step-2-2.png";
import step2_3 from "../../images/apps/gcs/instructions/step-2-3.png";
import step3_1 from "../../images/apps/gcs/instructions/step-3-1.png";
import step3_2 from "../../images/apps/gcs/instructions/step-3-2.png";
import step4 from "../../images/apps/gcs/instructions/step-4.png";
import step5 from "../../images/apps/gcs/instructions/step-5.png";
import step6 from "../../images/apps/gcs/instructions/step-6.png";
import step7_1 from "../../images/apps/gcs/instructions/step-7-1.png";
import step7_2 from "../../images/apps/gcs/instructions/step-7-2.png";
import step8_1 from "../../images/apps/gcs/instructions/step-8-1.png";
import step8_2 from "../../images/apps/gcs/instructions/step-8-2.png";
import step9_1 from "../../images/apps/gcs/instructions/step-9-1.png";
import step9_2 from "../../images/apps/gcs/instructions/step-9-2.png";
import step9_3 from "../../images/apps/gcs/instructions/step-9-3.png";
import accountRemovalNote from "../../images/apps/gcs/instructions/account-removal-note.png";
import feature1 from "../../images/apps/gcs/features/feature-1.png";
import feature2 from "../../images/apps/gcs/features/feature-2.png";
import feature3 from "../../images/apps/gcs/features/feature-3.png";
import feature4 from "../../images/apps/gcs/features/feature-4.png";
import feature5 from "../../images/apps/gcs/features/feature-5.png";
import feature6 from "../../images/apps/gcs/features/feature-6.png";

const gcsData = {
  name: "Google Calendar Sync",
  introText:
    "Introducing the ultimate solution to seamlessly connect your monday boards with any of your Google Calendars. Say goodbye to sharing personal calendars with the team or creating new Gmail accounts just for calendar syncing purposes.",
  subtext: "Now you can sync your monday.com board to your Google calendar in press of a button",
  image: mainImage,
  clientId: "ef5eb3e5fbb2ad312350e77f5c0597b5",
  promoVideoId: "ZMzmgp4Rysw",
  demoVideoId: "qg_tYfbkGBM",
  features: [
    {
      name: "Sync board to any Google Calendar",
      text: "Now you can sync your boards to any of your Google Calendars, not just the primary one",
      image: feature1,
    },
    {
      name: "Send invites to teams and clients",
      text: "Just choose the desired columns and invites will be sent automatically, saving you a ton of time",
      image: feature2,
    },
    {
      name: "Custom Description Builder",
      text: "Build your own custom descriptions for your calendar events using item column values",
      image: feature3,
    },
    {
      name: "Highly customizable integration settings",
      text: "Choose whether to sync 1-way or 2-way based on your specific needs",
      image: feature4,
    },
    {
      name: "Create event on status change",
      text: "Ability to create calendar event on status change",
      image: feature5,
    },
    {
      name: "Delete integration rules",
      text: "Now no need to delete events from both places, just delete from one place and let app do the rest",
      image: feature6,
    },
  ],
  instructions: [
    {
      text: "Open the board which you want to connect with your calendar. And then press the plus button and add the 'Google Calendar Sync' board view from the installed apps.",
      images: [step1],
    },
    {
      text: "When you open the app for the first time it will ask you to connect with Google Calendar calendar. You will need to authorize the app and accept the permissions the app requires. Remember you need to give both the calendar permissions the app asks for to make sure that it works properly.",
      images: [step2_1, step2_2, step2_3],
    },
    {
      text: "After account is connected successfully, refresh your browser and you will be able to see the connection setup screen. Here you can select the calendar from the added Google account which you want to link to. If you want you can also add another Google account, by selecting the 'Add another account' option.",
      images: [step3_1, step3_2],
    },
    {
      text: "After selecting the calendar, select columns from your board which should map to event fields. Selecting column mapping for the fields 'Event Title', 'Start Time', 'End Time' is mandatory. The other fields 'Description', 'Event Status' and 'Event Link' are optional. See demo video given below to understand more about what these fields mean.",
      images: [step4],
    },
    {
      text: "After seleting the desired mapping, press 'Create Connection' button. Once the connection is created successfully, you will see several buttons on the screen. 'Edit' allows you to edit the field-column mapping but it does not allow you to change the Google Account or Calendar value. If you want to change those, you will first have to 'Delete' the connection. The 'Manage Integration' button takes you to integration management page.",
      images: [step5],
    },
    {
      text: "On the integration management page, you will see various rules you can enable or disable to get desired integration. Enabling 1st and 2nd rules only will create a 1-way sync from monday to Google Calendar, whereas enabling 3rd and 4th rules only will create a 1-way integration from Google Calendar to monday. Enabling all the first four rules will give you a full 2-way sync between monday and Google Calendar, which is also the default setting when you create a new connection.",
      images: [step6],
    },
    {
      text: "The 5th rule here allows you to have more control over the integration by creating events only on specific status value change instead of every item creation. You can enable it and disable the 1st rule if you want to create new events only on status change.",
      images: [step7_1, step7_2],
    },
    {
      text: "Once you are done with setting up the connection and desired integration rules, you are good to go. Depending on integration rules you have selected, items/events will be created/updated in the board/calendar respectively. Furthermore you can link different calendars to different boards, but still see all your events in single calendar view, further improving your team's organization and collaboration.",
      images: [step8_1, step8_2],
    },
    {
      text: "Furthermore you can link different calendars to different boards, but still see all your events in single calendar view, further improving your team's organization and collaboration.",
      images: [step9_1, step9_2, step9_3],
    },
    {
      text: "Note: Currently the app supports linking one Google account with one monday account (company) only. Also one calendar instance (i.e. email-calendar pair) can only be linked to one board at a time. But if it is a shared calendar, every email account having access to that calendar can add it to a separate board. Lastly, your Google account connection with the app can be removed by clicking the delete button as shown, but you can only remove a Google account if there is no board connection associated with it.",
      images: [accountRemovalNote],
      noIndex: true,
    },
    {
      text: "Permissions: To create, update, delete a board-calendar connection, the following permission are required.",
      subpoints: [
        "(i) User must be a board owner to be able to create or delete a connection for the respective board",
        "(ii) User must be Google account owner to be able to update connection mapping or integration rules of a connection which is linked to their Google Account",
        "(iii) As per rules (i) and (ii), user needs to be both a board owner as well as Google Account owner if they want to create a board connection to calendar of a specific Google Account",
      ],
      images: [],
      noIndex: true,
    },
  ],
  faqs: [
    {
      question: "Do I need to buy this app for each board/user separately ?",
      answer:
        "No, you only have to purchase it on a company level, and then you can use with all your boards/users.",
    },
    {
      question: "What is pricing based on ?",
      answer:
        "Pricing of this app is based on the number of item-event links created per month. For example, if you have 10 items and each item has 2 events created/synced for it, then it will be counted as 20 links.",
    },
    {
      question: "It automatically creates events for all items, can I stop it ?",
      answer:
        'Yes, you can stop it anytime by going to the "Manage Integration" page and disabling the first rule and enabling the fifth rule, which only creates event on specific status change.',
    },
    {
      question: "Why can't I use lat/long based location column for location field ?",
      answer:
        "Google Calendar does not support lat/long based location field in calendar event, hence currently only text based columns can be selected for location field mapping.",
    },
  ],
};

export default gcsData;
