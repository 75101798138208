const termsData = {
  title: "Terms of Service",
  updated: "05 March 2024",
  sections: [
    {
      heading: "Terms of Service",
      children: [
        {
          text: "Welcome to Super Workday. This document serves as a legally binding agreement between you (the user) and us (the company).",
        },
        {
          text: 'The terms "us", "we", "our", and "the company" refer to our application. The term "you" refers to the user of our application.',
        },
      ],
    },
    {
      heading: "Acceptance of Terms",
      children: [
        {
          text: "By using any of our application, you agree to the terms and conditions outlined in this document. Currently, our apps include the following:",
        },
        {
          list: ["Schedule Recurring Tasks", "Google Calendar Sync", "Special Automations"],
        },
      ],
    },
    {
      heading: "Data Collection And Usage",
      children: [
        {
          text: "Please consult our Privacy Policy to learn about the information we collect and how it is used.",
        },
      ],
    },
    {
      heading: "Changes to the Terms",
      children: [
        {
          text: "We reserve the right to modify these terms at any time. We will notify you of any changes, and your continued use of our application after such notification will constitute your acceptance of the revised terms.",
        },
      ],
    },
    {
      heading: "Contact Us",
      children: [
        {
          text: "For any questions or concerns about these terms, please contact us at support@superworkday.com.",
          linkText: "support@superworkday.com",
          link: "mailto:support@superworkday.com",
        },
      ],
    },
  ],
};

export default termsData;
