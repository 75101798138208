import mainImage from "../../images/apps/sa/main.png";
import step1 from "../../images/apps/sa/instructions/step-1.png";
import step2 from "../../images/apps/sa/instructions/step-2.png";
import step3 from "../../images/apps/sa/instructions/step-3.png";
import step4 from "../../images/apps/sa/instructions/step-4.png";
import feature1 from "../../images/apps/sa/features/feature-1.png";
import feature2 from "../../images/apps/sa/features/feature-2.png";
import feature3 from "../../images/apps/sa/features/feature-3.png";
import feature4 from "../../images/apps/sa/features/feature-4.png";
import feature5 from "../../images/apps/sa/features/feature-5.png";

const saData = {
  name: "Special Automations",
  introText:
    "Introducing the ultimate solution to your automation challenges on monday.com! Say goodbye to suboptimal workarounds and welcome a powerful, efficient, and flexible tool that revolutionizes the way you manage special automations.",
  subtext: "Supercharge you monday workflows with special powerful automations",
  image: mainImage,
  clientId: "d20cc1f777a167bc1d542cb6a69e449d",
  promoVideoId: "qzyHAmJgHY4",
  demoVideoId: "qzyHAmJgHY4",
  features: [
    {
      name: "Dropdown automations",
      text: "Get the largest collection of dropdown automations to automate your workflows",
      image: feature1,
    },
    {
      name: "Subitem automations",
      text: "Handpicked collection of most powerful subitem automations for your use cases",
      image: feature2,
    },
    {
      name: "Mirror and Connect Board automations",
      text: "No more worries about data getting out of sync, let this app do it for you",
      image: feature3,
    },
    {
      name: "Automations for copying columns",
      text: "No more manual copying of columns, easily copy item/subitem columns with these automations",
      image: feature4,
    },
    {
      name: "Over 40+ powerful automations",
      text: "Checkbox, time delay, dependency column, date calculations, and many more automations just for you",
      image: feature5,
    },
  ],
  instructions: [
    {
      text: "To add automation recipes from this app, first open the Automation center and search for 'Special Automations'.",
      images: [step1],
    },
    {
      text: "Click on the desired integration recipe you want to configure",
      images: [step2],
    },
    {
      text: "Some integration recipes like this one have field of 'subitem column' like 'subitem number' in this case. This dropdown will only be populated if you have already added number type column in your subitems of the board.",
      images: [step3],
    },
    {
      text: "After filling in all the relevant fields of a recipe, click 'Add To Board', and automation recipe will be added to your board. You can selectively set the automation recipes you require for your use cases.",
      images: [step4],
    },
  ],
  faqs: [
    {
      question: "What is pricing for this app based on ?",
      answer:
        "The pricing is based on the number of operations performed per month. Each automation run can consume multiple operations depending on the complexity of the automation recipe.",
    },
    {
      question: "How many operations are consumed for each automation recipe ?",
      answer:
        "Number of operations consumed by an automation run depends on number of items affected by that run. For example, a simple recipe that copies a column to another column will consume 1 operation per run, whereas a recipe that deletes 10 items in 1 run will consume 10 operations.",
    },
    {
      question: "The automation I need is not available in this app, can you add it ?",
      answer:
        "Yes, we do add new automation recipes on a regular basis. Please reach out to us at support@superworkday.com if you have any specific automations in mind.",
    },
  ],
};

export default saData;
