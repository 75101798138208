const List = ({ list }) => {
  return (
    <ul className="flex flex-col gap-4 p2-r text-secondary-dark">
      {list.map((item) => (
        <li>
          {"->"} {item}
        </li>
      ))}
    </ul>
  );
};

export default List;
